/* 16. Schedule */
.schedule-area {
    position: relative;
    .table-wrap {
        position: relative;
        z-index: 3;
        @media(max-width: 768px) {
            overflow-x: scroll;
        }
    }
    .table {
        text-align: center;
        .name {
            padding: 28px 0px;
        }
        tr {
            transition: .5s;
            td {
                font-weight: 400;
                vertical-align: middle;
                border: 1px solid transparent;
            }
            .name {
                font-weight: 400;
                border: 1px solid transparent;
                text-transform: capitalize;
            }
            &:hover {
                background-color: $light;
                color: $heading-text;
                box-shadow: 0 5px 20px $shadow;
                td,
                th.name {
                    border: 1px solid transparent;
                }
            }
        }
    }
    .thead-light {
        th {
            background: $primary-color;
            border: 0;
            vertical-align: middle;
            padding: 30px 0;
        }
        .head {
            text-transform: uppercase;
            font-weight: 700;
            color: $light!important;
        }
    }
    .featured-img {
        position: absolute;
        right: 0;
        bottom: -30px;
        z-index: 2;
        @media (max-width: 992px) {
            display: none;
        }
    }
}