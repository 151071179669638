// Font 
$heading-font: 'Rubik', sans-serif;
$body-font: 'Roboto', sans-serif;

// Color 
$primary-color: #f61b55;
$heading-text: #222222;
$body-text: #777777;
$light: #ffffff;
$dark: #f5f5f5;
$footer: #080b1c;
$border: #eeeeee;
$shadow: rgba(0,0,0,.1);
$overlay: rgba(1,2,12,.7);
$overlay2: rgba(255,255,255,.8);
$overlay3: rgba(108,174,255,.2);
$overlay4: rgba(255,255,255,.3);
$overlay5: rgba(1,1,11,.5);
$overlay6: rgba(246, 27, 85,.8);
$placeholder: #b0b0b0;