/* 1. Author CSS */
body {
    padding: 0 10% 0;
    color: $body-text;
    font-family: $body-font;
    font-weight: 400;
    @include desktop() {
        padding: 0 5%;
    }
    @include tablet() {
        padding: 0 5%;
    }
    @include landscape-phone() {
        padding: 0 5%;
    }
    @include portrait-phone() {
        padding: 0 5%;
    }
}
h1 {
  color: $heading-text;
  text-transform: uppercase;
  font-family: $heading-font;
  font-size: 36px!important;
  font-weight: 500;
}
h2 {
  color: $heading-text;
  text-transform: uppercase;
  font-family: $heading-font;
  font-size: 36px!important;
  font-weight: 500;
}
h3 {
    color: $heading-text;
    text-transform: uppercase;
    font-family: $heading-font;
    font-size: 20px!important;
    font-weight: 400;
}
h4 {
    color: $light;
    font-family: $heading-font;
    font-size: 18px!important;
    font-weight: 400;
}
h5 {
    color: $heading-text;
    font-family: $heading-font;
    text-transform: uppercase;
    font-size: 14px!important;
    font-weight: 500;
}
h6 {
    color: $body-text;
    font-family: $body-font;
    text-transform: capitalize;
    font-size: 12px!important;
    font-weight: 400;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
a:hover {
    text-decoration: none;
}
a:focus {
    text-decoration: none;
}
input:focus,
textarea:focus {
    outline: none;
}