/* 14. About */
.banner-area.about-page {
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $overlay5;
        z-index: -1;
    }
    h3,
    span {
        color: $light;
    }
    a {
        color: $light;
        text-transform: capitalize;
        transition: .3s;
        &:hover {
            color: $primary-color;
        }
    }
    .banner-text {
        padding: 0;
    }
}