// Responsive Breakpoint
@mixin portrait-phone() {
    @media (max-width: 575.98px) {
        @content;
    }
}
@mixin landscape-phone() {
    @media (min-width: 576px) and (max-width: 767.98px) {
        @content;
    }
}
@mixin tablet() {
    @media (min-width: 768px) and (max-width: 991.98px) {
        @content;
    }
}
@mixin desktop() {
    @media (min-width: 992px) and (max-width: 1199.98px) {
        @content;
    }
}
