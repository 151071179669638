/* 18. Contact */
.footer-area.footer {
    background: $footer;
    h5,
    p {
        color: $light;
    }
    .footer-copyright {
        span {
            color: $light;
        }
    }
}

.contact-area .social-icons {
  ul{
    display: flex;
    li {
      margin: 5px;
      i {
        display: inline-block;
        padding: 10px;
        color: #222222;
        background: #f5f5f5;
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 20px;
        transition: 0.3s;
        border-radius: 5px;
        &:hover {
          background: $primary-color;
          color: white;
        }
      }
    }

  }
}



#mapBox {
    width: 100%;
    height: 420px;
    overflow: hidden;
}

.info-text {
    h5 {
        text-transform: inherit;
    }
}

.into-icon {
    i {
        font-size: 20px;
        color: $primary-color;
        margin-right: 20px;
    }
}
.contact-form,
.commentform-area {
    form {
        .left {
            width: 48%;
            float: left;
            overflow: hidden;
            margin-right: 4%;
            @include portrait-phone() {
                width: 100%;
                float: none;
            }
            input {
                width: 100%;
                border: 1px solid $border;
                padding: 12px 20px;
                margin-bottom: 20px;
            }
        }
        .right {
            width: 48%;
            overflow: hidden;
            @include portrait-phone() {
                width: 100%;
                float: none;
            }
            textarea {
                width: 100%;
                border: 1px solid $border;
                padding: 12px 20px;
            }
        }
        button {
            margin-top: 20px;
            float: right;
            @include portrait-phone() {
                float: left;
            }
        }
    }
}
