/* 17. Other */
.section-padding {
    padding: 130px 0;
}
.section-padding2 {
    padding-top: 130px;
}
.section-padding3 {
    padding-bottom: 130px;
}
.section-top {
    margin-bottom: 80px;
    p {
        font-style: italic;
    }
}