/* 8. Coaches */
.single-coaches {
    &:hover {
        .hover-state {
            opacity: 1;
            visibility: visible;
        }
        .coaches-footer {
            background: $light;
            box-shadow: 0 10px 20px $shadow;
            h5 {
                color: $primary-color;
            }
        }
    }
}
.coaches-img {
    position: relative;
    img {
        width: 100%;
    }
    .hover-state {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $overlay;
        transition: .5s;
        opacity: 0;
        visibility: hidden;
        ul {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            li {
                display: block;
                margin: 10px 0;
                a {
                    i {
                        color: $light;
                        background: $overlay4;
                        border-radius: 50%;
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        line-height: 20px;
                        padding: 10px;
                        transition: .3s;
                        &:hover {
                            color: $light;
                            background: $primary-color;
                        }
                    }
                }
            }
        }
    }
}
.coaches-footer {
    padding: 40px 30px 30px;
    background: $dark;
    margin: -50px 10% 0;
    transition: .5s;
    position: relative;
    z-index: 2;
}

