/* 3. Main Menu */
.main-menu {
  ul {
    float: right;
    border-radius: 15px;
    @include desktop() {
      float: left;
      position: absolute;
      top: 60px;
      right: 0;
      z-index: 4;
      background: $dark;
      width: 40%;
      padding: 20px 20px 30px;
    }
    @include tablet() {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      float: left;
      position: absolute;
      top: 25px;
      left: 0;
      z-index: 4;
      background: $dark;
      width: 100%;
      padding: 20px 20px 30px;
    }
    @include landscape-phone() {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      float: left;
      position: absolute;
      top: 25px;
      left: 0;
      z-index: 4;
      background: $dark;
      width: 100%;
      padding: 20px 20px 30px;
    }
    @include portrait-phone() {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      float: left;
      position: absolute;
      top: 18px;
      left: 0;
      z-index: 4;
      background: $dark;
      width: 100%;
      padding: 20px 20px 30px;
    }

    li {
      display: inline;
      position: relative;
      @include desktop() {
        display: block;
      }
      @include tablet() {
        display: block;
      }
      @include landscape-phone() {
        display: block;
      }
      @include portrait-phone() {
        display: block;
      }

      a {
        color: $heading-text;
        font-family: $body-font;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
        padding: 15px;
      }

      &:hover {
        ul.sub-menu {
          opacity: 1;
          visibility: visible;
          top: 100%;
          left: 30px;
        }
      }

      ul.sub-menu {
        padding: 10px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 250%;
        left: 0;
        width: 250px;
        background: $dark;
        z-index: 2;
        transition: .5s;

        li {
          display: block;

          a {
            transition: .3s;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .menu-btn {
    display: inline;
    margin-left: 60px;
    @include desktop() {
      margin-left: 0;
    }
    @include tablet() {
      margin-left: 0;
      .login {
        display: block;
      }
    }
    @include landscape-phone() {
      margin-left: 0;
      .login {
        display: block;
      }
    }
    @include portrait-phone() {
      margin-left: 0;
      .login {
        display: block;
      }
    }
  }
}

.main-menu.main-menu-light {
  ul {
    @include tablet() {
      background: $footer;
    }
    @include landscape-phone() {
      background: $footer;
    }
    @include portrait-phone() {
      background: $footer;
    }

    ul.sub-menu {
      background: $footer;
    }
  }

  a {
    color: $light;
  }
}

.custom-navbar {
  position: absolute;
  top: -42px;
  right: 15px;
  background: $primary-color;
  padding: 7px 10px;
  border-radius: 3px;
  transition: .2s;
  cursor: pointer;
  display: none;
  @include desktop() {
    top: 0;
    display: block;
  }
  @include tablet() {
    display: block;
  }
  @include landscape-phone() {
    display: block;
  }
  @include portrait-phone() {
    display: block;
  }

  span {
    background: $light;
    display: block;
    width: 30px;
    margin: 6px 0;
    height: 2px;
  }
}