/* 5. Banner */
.banner-area {
    padding: 100px 0 90px;
  margin-top: 80px;
    @include desktop() {
        margin-top: 0;
    }
    @include tablet() {
        margin-top: 0;
    }
    @include landscape-phone() {
        margin-top: 0;
    }
    @include portrait-phone() {
        margin-top: 0;
    }
}
.banner-bg {
    background-color: $placeholder;
    background-image: url('../../images/banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    .banner-text {
      text-align: center;
        padding: 30px 30px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        @include portrait-phone() {
            padding: 15px;
        }
      h1 {
        color: white;
      }
    }
    .template-btn {
        margin-top: 30px;
        &:hover {
            color: $light!important;
        }
    }
}