/* 4. Header */
.header-area.main-header {
    padding: 15px 0;
    background: $light;
    //border-bottom-left-radius: 15px;
    //border-bottom-right-radius: 15px;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    max-width: 100vw;

    z-index: 9;
    @include desktop() {
        margin: 0;
    }
}