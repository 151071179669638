/* 15. Button */
.template-btn {
    color: $light!important;
    background: $primary-color;
    font-family: $heading-font;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid transparent;
    display: inline-block;
    padding: 13px 24px!important;
    border-radius: 3px;
    transition: all .5s;
    &:hover {
        color: $heading-text!important;
        background: transparent;
        border: 1px solid $primary-color;
    }
}