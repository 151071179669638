/* 12. Friend */
.friend-area {
    background-color: $placeholder;
    background-image: url('../../images/sale-banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    .heading {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 10px 0px;
      border-radius: 10px;
    }
    h2 {
        color: $light;
    }
    .template-btn {
        &:hover {
            color: $light!important;
        }
    }
}