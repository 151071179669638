/* 11. Client */
.client-slider.owl-carousel {
    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
}


.single-slide {
    background: $dark;
    padding: 30px 20px;
    transition: .5s;
    margin: 0 5px 20px;
    &:hover {
        background: $light;
        box-shadow: 0 10px 20px $shadow;
    }
}